import React from 'react';

import { IconComponentProps } from '@backstage/core-components';
import HomeIcon from '@mui/icons-material/Home';

// Wrap icon to be able to use with backstage.
// This shouldn't be needed anymore with backstage 1.32.0
// https://github.com/backstage/backstage/commit/bfd4bec795c62550c1ee207f15a9a39321d796d9
type MuiIcon = typeof HomeIcon;
export const muiIconToBackstageIcon: (
  Icon: MuiIcon,
) => (props: IconComponentProps) => ReturnType<MuiIcon> = Icon => props => {
  let { fontSize } = props;
  if (fontSize === 'default') {
    fontSize = undefined;
  }
  return <Icon fontSize={fontSize} />;
};
