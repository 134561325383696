import React, { PropsWithChildren } from 'react';

// Material-ui imports
import BuildIcon from '@mui/icons-material/Build';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import ExtensionIcon from '@mui/icons-material/Extension';
import HomeIcon from '@mui/icons-material/Home';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import MapIcon from '@mui/icons-material/MyLocation';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

// Core imports
import {
  Sidebar,
  SidebarPage,
  sidebarConfig,
  SidebarItem,
  SidebarDivider,
  SidebarSpace,
  SidebarGroup,
  SidebarScrollWrapper,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';

// Plugin imports
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import { GraphiQLIcon } from '@backstage-community/plugin-graphiql';

// Local imports
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

import { muiIconToBackstageIcon } from '../../Utils';

const useSidebarLogoStyles = () => ({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const styles = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div style={styles.root}>
      <Link to="/" underline="none" style={styles.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem
          icon={muiIconToBackstageIcon(HomeIcon)}
          to="catalog"
          text="Home"
        />
        <SidebarItem
          icon={muiIconToBackstageIcon(ExtensionIcon)}
          to="api-docs"
          text="APIs"
        />
        <SidebarItem
          icon={muiIconToBackstageIcon(LibraryBooks)}
          to="docs"
          text="Docs"
        />
        <SidebarItem
          icon={muiIconToBackstageIcon(CreateComponentIcon)}
          to="create"
          text="Create..."
        />
        <SidebarItem
          icon={muiIconToBackstageIcon(BuildIcon)}
          to="entity-validation"
          text="Validator"
        />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={GraphiQLIcon} to="graphiql" text="GraphiQL" />
          <SidebarItem
            icon={muiIconToBackstageIcon(MapIcon)}
            to="https://tech-radar.helloprima.com/"
            text="Tech Radar"
          />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
